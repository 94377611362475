<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <v-skeleton-loader>

        </v-skeleton-loader>
            <v-row>
                <v-col class="mx-auto"
                cols="12"
                sm="7">
                    <v-btn
                    class="my-4"
                    color="primary"
                    @click="()=>{
                        this.$router.go(-1);
                    }">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                    <v-card class="pa-4">
    
                        <v-toolbar class="secondary" height="auto">
                            <v-toolbar-title class="white--text text-h4">
                                Role edit
                            </v-toolbar-title>
                        </v-toolbar>
                        <div class="d-flex flex-row">
                            <v-text-field v-model="name" class="mt-4" label="Name" disabled filled dense outlined>
                            </v-text-field>        
                        </div>
                        
                        <div class="d-flex flex-row">
                            <v-autocomplete label="Roles.."  :items="roles" item-text="name" v-model="rolesSelected" return-object small-chips outlined dense multiple deletable-chips>
                            </v-autocomplete>
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn color="primary" plain>
                                Cancel
                            </v-btn>
                            <v-btn color="primary" @click="validateInput" :loading="api.isLoading" >
                                Confirm
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
                
            </v-row>
    </v-container>
</template>
  
<script>

import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    //
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        name:null,
        roles:[
            {
                "name":"salesperson ( vision )",
                "value":'salesperson',
            },
            { "name":"customer service ( vision )",
              "value":"customer_service",
            }
            ,{
                "name":"operation ( vision )",
                "value" : "operation",
            },
            {
                "name":"sales manager ( vision )",
                "value":"sales_manager"
            },
            {
                "name":'marketing ( vision )',
                "value":'marketing',
            },
            {
                "name":"sales team leader ( vision )",
                "value":'sales_team_lead',
            },
            {
                "name":"admin ( vision )",
                "value":'vision_admin'
            },
            {
                "name":'tax expert ( AAE )',
                "value":"tax_expert"
            },
            {
                "name":'consultant ( AAE )',
                "value":"consultant",
            },
            {
                "name":'client ( AAE )',
                'value':"client",
            },
            {
                "name":"admin ( AAE )",
                "value":'admin'
            },
            {
                "name":"manager ( AAE )",
                "value":"manager",
            }
        ],
        rolesSelected:null,
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getUserRole"){
                this.name = resp.data[0].user_name;
                // this.roles = resp.data;
                let tempRoleSelected =[];
                resp.data.forEach(element => {
                    let tempRole =
                    {
                        // 'role_id':element.role_id,
                        'name':(element.role_name=='vision_admin')?"admin":element.role_name.replaceAll("_"," "),
                        'value':element.role_name
                    }

                    tempRoleSelected.push(tempRole);
                });
                this.rolesSelected = tempRoleSelected;
              
            }
            if(resp.class=="updateUserRole"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Role";
            }
               
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
    //  
        fetch(){
            let fetchUserRoleApi = this.fetchUserRole();
            this.$api.fetch(fetchUserRoleApi);
        },
        fetchUserRole(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/role/user/id/'+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let updateStaffRoleApi = this.updateStaffRole();
            this.$api.fetch(updateStaffRoleApi);

        },
        updateStaffRole(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/role/user/id/"+this.$router.history.current.params.id;
            let formData = new FormData;
            this.rolesSelected.forEach(role => {
                formData.append('roles[]',role.value);
            });
            tempApi.params = formData;
            return tempApi;
        },
        cancelSubmit(){
            this.isPending =false;
        },
        redirectBack(){
            this.$router.go(-1);
        },
    }
}
</script>